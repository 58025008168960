import React from 'react'
import {isFunction, get, flowRight as compose, isEmpty, toLower, includes, toString, last, orderBy, filter, findIndex} from 'lodash'
import vhCheck from 'vh-check'
import classnames from 'classnames'
import {withRouter} from 'react-router-dom'
import {clientTypes, dueDiligenceStatuses} from '@bdswiss/common-enums'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Badge from '@mui/material/Badge'
import SvgIcon from '@mui/material/SvgIcon'
import UiNotification from '../Common/UiNotification'
import AppRoutes from './routes'
import AppContext from '../Common/contexts/AppContext'
import messages from '../../assets/messages'
import {ReactComponent as DashboardIcon} from '../../assets/images/dashboardBottomNav.svg'
import {ReactComponent as AccountsIcon} from '../../assets/images/accountsBottomNav.svg'
import {ReactComponent as MoreIcon} from '../../assets/images/moreBottomNav.svg'
import {ReactComponent as NotificationsIcon} from '../../assets/images/supportBottomNav.svg'
import {ReactComponent as PaymentsIcon} from '../../assets/images/paymentsBottomNav.svg'
import {ReactComponent as PartnerZoneIcon} from '../../assets/images/partnerZoneButtonNav.svg'
import Button from '@mui/material/Button'
import {getCurrentTheme, getPendingVerificationsCount, isDarkTheme, isIos, getAllowedAccounts,
  setCookie, getCookie, logEventCustomParams, hasWebTrader, isMobile, isIBClient,
} from '../../common/utils'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Images from '../Common/Images'
import config from '../../config'
import ForexTradeNow from '../Accounts/Forex/ForexTradeNow'
import {Divider} from '@mui/material'

const styles = theme => ({
  wrap: {
    height: 'calc(100vh - var(--vh-offset, 0px))',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    '-webkit-overflow-scrolling': 'touch',
    paddingLeft: 0,
    paddingRight: 0
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 56,
  },
  bottomNavigationRoot: {
    // stroke: theme.palette.mode === 'dark' ? '#999999' : theme.palette.lightgrey.color,
    color: theme.palette.mode === 'dark' ? '#999999' : '#858585',
    textAlign: 'center',
    fontWeight: 'normal'
  },
  bottomNavigationSelected: {
    stroke: theme.palette.mode === 'dark' ? '#419CFF' : '#419CFF',
    color: theme.palette.mode === 'dark' ? '#419CFF' : '#419CFF',
    fontWeight: 'normal',
    textAlign: 'center',
    background: 'none'
  },
  bottomNavigationWrapper: {
    // boxShadow: `0 0 4px ${theme.palette.lightgrey.color}`,
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 2,
    borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB'}`,

  },
  invisible: {
    display: 'none'
  },
  badge: {
    backgroundColor: theme.palette.red.color,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' : 'absolute',
    borderRadius: '32px',
    width: 24,
    height: 16,
    padding: '0px 5px',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  floatingButton: {
    width: 48,
    height: 48,
    minWidth: 48,
    background: theme.palette.lightgreyBackgroundSolid.color,
    borderRadius: '50%',
    boxShadow:theme.boxShadowStyle,
    color: theme.palette.primary.main,
    position: 'fixed',
    bottom: 75,
    right: 17,
    '&:hover':{
      background: theme.palette.lightgreyBackgroundSolid.color,
    },
    '&.disabled': {
      pointerEvents: 'none',
      color: theme.palette.grey.color,
    },
    paddingTop: 4,
    fontSize: 28,
    fontWeight: 300,
  },
  plus: {
    fontSize: 28,
    fontWeight:300
  },
  banner: {
    backgroundColor: '#F1F8FF',
    justifyContent: 'space-between',
    zIndex: 9999,
  },
  bannerDark: {
    backgroundColor: '#08233F',
    justifyContent: 'space-between'
  },
  bannerTitle: {
    [theme.breakpoints.down('md')]: {
      lineHeight: '20px',
    }
  },
  bannerSubtitle: {
    [theme.breakpoints.down('md')]: {
      lineHeight: '14px',
    }
  },
  bannerButton: {
    color: theme.palette.common.white,
    padding: '4px 16px',
    borderRadius: '24px',
    backgroundColor: '#007AFF',
    '&:hover': {
      backgroundColor: '#007AFF',
    },
    '&.dark': {
      backgroundColor: '#419CFF',
      '&:hover': {
        backgroundColor: '#419CFF',
      }
    },
  },
  bannerCloseIcon: {
    width: '16px',
    height: '16px',
    color: '#858585',
    '&.dark': {
      color: '#999999'
    }
  },
  buttonClass: {
    marginTop: 20,
    width: 192,
    height: 40,
    padding: 4,
    minHeight: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'inherit',
      marginTop: 0,
    },
  },
  stickyButtonContainer: {
    position: 'fixed',
    bottom: 60,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '12px 16px',
  },
  moreBadge:{
    marginTop: 5,
  }
})

class Mobile extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      bottomNavigationActions: [],
      downloadAppBannerOpen: !isEmpty(getCookie('download_app_banner'))
        ? getCookie('download_app_banner') === 'true'
        : true,
    }
    vhCheck()
  }

  componentDidMount() {
    const {clientType} = this.context
    const {common: {partnersLoyaltyAllowed}, showPartnerZone} = config
    const {viewer: {id, dueDiligences, pendingNoticesCount = 0, appropTests, globalQuestionnaire, documents, referralDetails}, viewer, classes, t} = this.props
    const dueDiligence = last(orderBy(filter(dueDiligences, (a)=> a.status !== dueDiligenceStatuses.rejected.value)))
    const showPartnerZoneConditions = ((!isEmpty(dueDiligence)) || (includes(partnersLoyaltyAllowed, toString(id)))) && showPartnerZone && isIBClient(clientType)
    const badgeClassesArr = [classes.badge]
    const referralAwardNotification = get(referralDetails,'activatedReferralAwards') && !get(referralDetails.activatedReferralAwards,'claimedAt')
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClassesArr.push(classes.invisible)
    const moreBadgeClassesArr = [classes.badge, classes.moreBadge]
    const pendingVerificationsCount = viewer && getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire, documents, clientType)
    const pendingMoreCount = Number(totalNotificationsCount) + Number(pendingVerificationsCount)
    pendingMoreCount < 1 && moreBadgeClassesArr.push(classes.invisible)

    this.setState({
      bottomNavigationActions: [
        {path: '/dashboard', label: t(messages.dashboard.i18nKey, messages.dashboard.defaults), icon: <SvgIcon><DashboardIcon/></SvgIcon>},
        showPartnerZoneConditions && {path: '/partner-zone', label: t(messages.partner.i18nKey, messages.partner.defaults), icon: <SvgIcon><PartnerZoneIcon/></SvgIcon>},
        {path: '/accounts', label: t(messages.accounts.i18nKey, messages.accounts.defaults), icon: <SvgIcon><AccountsIcon/></SvgIcon>},
        {path: '/transactions', label: t(messages.payments.i18nKey, messages.payments.defaults), icon: <SvgIcon><PaymentsIcon/></SvgIcon>},
        !showPartnerZoneConditions && {path: '/support', label: t(messages.supportTitle.i18nKey, messages.supportTitle.defaults), icon: <SvgIcon><NotificationsIcon /></SvgIcon>},
        {path: '/more', label: t(messages.more.i18nKey, messages.more.defaults), icon: <Badge badgeContent={pendingMoreCount} classes={{badge: classnames(moreBadgeClassesArr)}}><SvgIcon><MoreIcon /></SvgIcon></Badge>},
      ].filter(Boolean)
    })
  }

  checkActiveTab() {
    return findIndex(this.state.bottomNavigationActions, (action) => this.props.location.pathname.includes(action.path))
  }

  handleDownloadMobileAppBannerClose() {
    this.setState({downloadAppBannerOpen: false})
  }

  renderDownloadMobileAppBanner() {
    const {common: {appLinks}} = config
    const themePreference = getCurrentTheme(this.context)
    const {companyObject} = this.context
    const {classes} = this.props

    return (
      <Toolbar className={isDarkTheme(themePreference) ? classes.bannerDark : classes.banner}>
        <Box display='flex' flexDirection='row' alignItems='center' gap='12px'>
          <img src={Images[`${toLower(companyObject.brand)}_app_logo.svg`]} alt='icon' width='32px' height='32px' />
          <Box display='flex' flexDirection='column' >
            <Typography variant='body2' className={classes.bannerTitle}> <Trans {...messages.downloadMobileAppBannerTitle} values={{company: companyObject.brandLabel}} /> </Typography>
            <Typography variant='caption' className={classes.bannerSubtitle}> <Trans {...messages.downloadMobileAppBannerSubtitle} values={{company: companyObject.brandLabel}} /> </Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
          <Button className={classnames(classes.bannerButton, isDarkTheme(themePreference) ? 'dark' : '')}
            onClick={() => {
              const firebaseParams = {
                brand: companyObject.brandLabel,
                source: isIos() ? 'iOS' : 'Android',
                userId: get(this.context, 'clientId'),
              }
              logEventCustomParams('onQRCodeMobileLinkClicked', firebaseParams)
              window.location.href = appLinks.autoDetectStoreUrl
            }}> <Trans {...messages.open} /> </Button>
          <IconButton
            style={{padding: 0}}
            onClick={() => {
              const firebaseParams = {
                brand: companyObject.brandLabel,
                source: isIos() ? 'iOS' : 'Android',
                userId: get(this.context, 'clientId'),
              }

              logEventCustomParams('onQRCodeBannerClosed', firebaseParams)
              setCookie('download_app_banner', false, 1)
              this.handleDownloadMobileAppBannerClose()
            }}
            size="large">
            <CloseIcon className={classnames(classes.bannerCloseIcon, isDarkTheme(themePreference) ? 'dark' : '')} />
          </IconButton>
        </Box>
      </Toolbar>
    )
  }

  render() {
    // const {downloadAppBannerOpen} = this.state
    const {notification, classes, t, history: {push}, viewer, viewer: {allowedAccountTypes, corporateSignupStep,
      corporateStatus}, location} = this.props
    const {clientType, accounts, companyObject} = this.context
    const activeTab = this.checkActiveTab()
    const allowedAccounts = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes || [], viewer)

    const corporateSignup = corporateSignupStep && !corporateStatus
    const isMorePath = includes(get(location, 'pathname'), '/more')

    const disableAddNewAccount = clientTypes[clientType]?.disableAddNewAccount  ||  viewer?.isContractor

    return (<React.Fragment>
      <div className={classes.wrap}>
        {/* {downloadAppBannerOpen && this.renderDownloadMobileAppBanner()} */}
        <Divider />
        <main className={classes.main}>
          <AppRoutes />
        </main>
        <div>
          {isMobile() && isMorePath && <div className={classes.stickyButtonContainer}>
            {hasWebTrader() && <ForexTradeNow accounts={accounts} type='button' buttonClass={classes.buttonClass}/>}
          </div>}
          {!corporateSignup && <BottomNavigation
            value={activeTab}
            classes={{root: classes.bottomNavigationWrapper}}
            onChange={(_, activeTab) => this.setState({activeTab})}
            showLabels
          >
            {this.state.bottomNavigationActions.map((action, index) => (
              <BottomNavigationAction
                classes={{root: classes.bottomNavigationRoot, selected: classes.bottomNavigationSelected}}
                onClick={() => push(action.path)}
                label={action.label}
                icon={action.icon}
                key={index}
              />
            ))}
          </BottomNavigation>}
        </div>
        {allowedAccounts.length > 0 && location.pathname === '/accounts' &&
          <Button className={classnames(classes.floatingButton, disableAddNewAccount ? 'disabled' : '')} classes={{label: classes.plus, paddingTop: isIos() ? 4 : 0}} onClick={() => push('/accounts/add-account')}>+</Button>}
        {
          notification &&
            <UiNotification
              open={!!notification}
              onClose={() => {
                isFunction(notification.onClose) && notification.onClose()
                this.props.onClose()
              }}
              type={notification.type}
              status={notification.status}
              buttonMessage={notification.buttonMessage}
              hideButton={notification.hideButton}
              subTitle={notification.subTitle}
              buttonAction={notification.buttonAction}
              linkAction={notification.linkAction}
              linkActionMessage={notification.linkActionMessage}
              subTitleCenter={notification.subTitleCenter}
              title={notification.title}
              hideTitle={notification.hideTitle}
              subTitleChildren={notification.subTitleChildren}
            >
              {notification.content}
            </UiNotification>
        }
      </div>
    </React.Fragment>)
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true})
)(Mobile)
